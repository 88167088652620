import { createRouter, createWebHistory } from 'vue-router';

import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'interfaces',
    component: () => import('../views/Main/BaseTemplate.vue'),
    children: [
      //Pagina de inicio
      {
        path: '',
        name: 'landing',
        meta: { title: 'Inicio - HANDY APP' },
        component: () => import('../components/landing/Landing.vue')
      },
      //Sección de sectores
      {
        name: 'subsectorsList',
        path: 'subsectores/:id',
        meta: { title: 'Subsectores - HANDY APP' },
        component: () => import('../components/sectors/SubsectorsList.vue'),
      },
      {
        name: 'descarga',
        path: 'descarga',
        meta: { title: 'Descargar - HANDY APP' },
        component: () => import('@/components/download/DownloadInterface.vue'),
      },
      //Sección de autenticación
      {
        path: 'registro',
        name: 'userRegister',
        meta: { title: 'Registro - HANDY APP' },
        component: () => import('../components/Register/UserRegister.vue')
      },
      {
        path: 'login',
        name: 'userLogin',
        meta: { title: 'Login - HANDY APP' },
        component: () => import('../components/Login/UserLogin.vue')
      },
      //Sección de recuperación de contraseña
      {
        path: 'contrasena/recuperar',
        name: 'recover',
        meta: {title: 'Recuperar contraseña - HANDY APP'},
        component: () => import('../components/user/UserRecoverPassword.vue')
      },
      {
        path: 'contrasena/cambiar',
        name: 'change',
        meta: {title: 'Cambiar contraseña - HANDY APP', requiresAuth: true},
        component: () => import('../components/user/UserChangePassword.vue')
      },
      //Sección de listado de ubicaciones
      {
        path: 'ubicaciones/lista/:id',
        name: 'locations',
        meta: { title: 'Listas - HANDY APP' },
        component: () => import('../components/Lists/LocationsList.vue')
      },
      {
        path: 'ubicaciones/filtros/:sector',
        name: 'filters',
        meta: { title: 'Filtros - HANDY APP' },
        component: () => import('../components/Lists/ListFilters.vue')
      },
      {
        path:'ubicaciones/detalles/:id',
        name: 'details',
        meta: {title: 'Detalles - HANDY APP'},
        component: () => import('../components/Details/LocationDetails.vue')
      },
      {
        name: 'mapView',
        path: 'ubicaciones/mapa/:sector',
        component: () => import('../components/map/Map.vue'),
        meta: { title: 'Mapa - HANDY APP' },
      },
      {
        name: 'search',
        path: 'ubicaciones/busqueda/:query',
        meta: { title: 'Búsqueda - HANDY APP' },
        component: () => import('../components/Lists/SearchedLocationsList.vue'),
      },
      //Sección de usuario
      {
        path:'usuario/configuracion',
        name: 'config',
        meta: {title: 'Configuración - HANDY APP', requiresAuth: true},
        component: () => import('../components/user/UserConfig.vue')
      },
      {
        path:'usuario/favoritos',
        name: 'favorites',
        meta: {title: 'Favoritos - HANDY APP', requiresAuth: true },
        component: () => import('../components/user/UserFavorites.vue')
      },
      //Sección estaticos
      {
        name: 'staticDetails',
        path: 'estaticos/detalles/:id',
        meta: { title: 'Estático detalles - HANDY APP' },
        component: () => import('../components/static/StaticDetails.vue'),

      },
      {
        name: 'staticList',
        path: 'estaticos/lista/:id',
        meta: { title: 'Estáticos - HANDY APP' },
        component: () => import('../components/static/StaticList.vue'),
      },
      //Sección participa
      {
        path:'participa/lista',
        name: 'participate',
        meta: {title: '¡Participa! - HANDY APP'},
        component: () => import('../components/participate/Participate.vue')
      },
      {
        path:'participa/detalles/:id',
        name: 'campaign',
        meta: {title: 'Campaña - HANDY APP'},
        component: () => import('../components/campaign/CampaignDetails.vue')
      },
      {
        path:'rgpd/:id',
        name: 'rgpd',
        meta: {title: 'RGPD - HANDY APP'},
        component: () => import('../components/static/RGPD.vue')
      },
      {
        name:'error',
        path: '/:pathMatch(.*)*',
        meta: { title: '404 - HANDY APP' },
        component: () => import('@/components/errors/404.vue'),
      },
    ]
  },
];



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});



router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'HANDY APP';
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
    }else{
      next();
    }
  }else{
    if ((to.path === '/login' || to.path==='/registro') && store.getters.isAuthenticated) {
      next({ path: '/' });
    }else{
      next();
    }
  
  }


});

export default router;
