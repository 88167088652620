import axios from 'axios'

axios.defaults.baseURL = 'https://handy.vivezone.com';
axios.defaults.headers.common['Authorization'] = 'Bearer ABCDE';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:8080';





