import { createStore } from 'vuex'
export default createStore({
    state: {
        filters:{
            location:[],
            commerceType:[],
        },
        authenticated: (localStorage.getItem('token') !== null && localStorage.getItem('token') !== undefined) ? true:false,
        querySearch: ''
    },
    mutations: {
        addLocationFilter(state, payload) {
            state.filters.location.push(payload)
        },
        addCommerceTypeFilter(state, payload) {
            state.filters.commerceType.push(payload)
        },
        removeLocationFilter(state, payload) {
            state.filters.location = state.filters.location.filter(item => item !== payload)
        },
        removeCommerceTypeFilter(state, payload) {
            state.filters.commerceType = state.filters.commerceType.filter(item => item !== payload)
        },
        setAuthenticated(state, payload) {
            state.authenticated = payload
        },
        setQuerySearch(state, payload) {
            state.querySearch = payload
        }
    },
    actions: {
        addLocationFilter(context, payload) {
            context.commit('addLocationFilter', payload)
        },
        addCommerceTypeFilter(context, payload) {
            context.commit('addCommerceTypeFilter', payload)
        },
        removeLocationFilter(context, payload) {
            context.commit('removeLocationFilter', payload)
        },
        removeCommerceTypeFilter(context, payload) {
            context.commit('removeCommerceTypeFilter', payload)
        },
        setAuthenticatedAction(context, payload) {
            context.commit('setAuthenticated', payload)
        },
        setQuerySearchAction(context, payload) {
            context.commit('setQuerySearch', payload)
        }
    },
    getters: {
        getLocationFilters(state) {
            return state.filters.location
        },
        getCommerceTypeFilters(state) {
            return state.filters.commerceType
        },
        isAuthenticated(state) {
            return state.authenticated;
        },
        getQuerySearch(state) {
            return state.querySearch
        }
    }
})

